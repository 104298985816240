export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Slot Machine',
    route: '',
    icon: 'DollarSignIcon',
    children: [
      /*  {
        title: 'Configura CAP',
        route: 'configure-cap',
        icon: 'MapPinIcon',
      },  */
      {
        title: 'Partecipanti',
        route: 'partecipants',
        icon: 'UsersIcon',
      },
      {
        title: 'Vincitori',
        route: 'winners',
        icon: 'GiftIcon',
      },
    ],
  },
  {
    title: 'Easter Slot Machine',
    route: '',
    icon: 'GiftIcon',
    children: [
      {
        title: 'Configura CAP',
        route: 'easter-configure-cap',
        icon: 'MapPinIcon',
      },
      {
        title: 'Partecipanti',
        route: 'easter-partecipants',
        icon: 'UsersIcon',
      },
      {
        title: 'Vincitori',
        route: 'easter-winners',
        icon: 'GiftIcon',
      },
    ],
  },
  {
    title: 'Calendario avvento',
    route: '',
    icon: 'CalendarIcon',
    children: [
      {
        title: 'Premi',
        route: 'prizesAvvento',
        icon: 'GiftIcon',
      },
      {
        title: 'Partecipanti',
        route: 'partecipantsAvvento',
        icon: 'UsersIcon',
      },
    ],
  },
  {
    title: 'Gratta e vinci',
    route: '',
    icon: 'AwardIcon',
    children: [
      {
        title: 'Partecipanti',
        route: 'partecipantsScratch',
        icon: 'UsersIcon',
      },
      {
        title: 'Vincitori',
        route: 'winnersScratch',
        icon: 'GiftIcon',
      },
    ],
  },
]
